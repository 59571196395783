.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h4:focus {
  outline: none;
}

.focusableHeader {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.focusableHeader .leader {
  font-size: 2.5rem;
  line-height: 1.235;
  letter-spacing: 0.00735rem;
}

/* added to flex page contents */
.MuiGrid-container {
  flex-wrap: nowrap;
}

.MuiGrid-item > ul {
  box-sizing: unset;
  padding-left: 0px;
}

/* ul management */
ul li {
  box-sizing: unset;
  list-style: circle;
  padding-left: 0px;
}

li {
  align-items: start;
}

li:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* authors in bibl css */
span {
  display: inline-block;
}

span.cflag {
  display: inline-block;
  padding-left: 4px;
  top: 1px;
  position: relative;
}

span.hobsbawm {
  display: inline-block;
  padding-right: 2px;

}

/*
a {
  display: inline-block;
  margin-right: 2px;
}

a * {
  letter-spacing: 0.1em;
}
*/


.MuiBreadcrumbs-ol {
  align-items: flex-end;
}

.MuiDivider-root {
  margin: 2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


